import instance from "@/axios"

/**  获取积分明细 */
export const getTaskListApi = async () => {
    return instance({
        url: '/dq_admin/dict/listSwitchTask',
        method: 'GET',
    })
}

/**  修改状态 */
export const changeTaskStatus = async (params) => {
    return instance({
        url: '/dq_admin/dict/switchTaskStatus',
        method: 'GET',
        params: params
    })
}