var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#ececec", padding: "20px" } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        _vm._l(_vm.taskListData, function(item, index) {
          return _c(
            "a-col",
            { key: index, staticClass: "mb-20", attrs: { span: 8 } },
            [
              _c(
                "a-card",
                { attrs: { title: item.taskName, bordered: false } },
                [
                  item.code !== "task_close_product_order"
                    ? _c(
                        "span",
                        { attrs: { slot: "extra" }, slot: "extra" },
                        [
                          _c("a-switch", {
                            attrs: {
                              "checked-children": "开",
                              "un-checked-children": "关",
                              checked: !!Number(item.value)
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleShowSwitch($event, item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        { attrs: { slot: "extra" }, slot: "extra" },
                        [
                          _c("a-switch", {
                            attrs: {
                              "checked-children": "开",
                              "un-checked-children": "关",
                              checked: !Number(item.value)
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleShowSwitch($event, item)
                              }
                            }
                          })
                        ],
                        1
                      )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }