<template>
  <div style="background-color: #ececec; padding: 20px;">
    <a-row :gutter="16">
      <a-col :span="8" class="mb-20" v-for="(item, index) in taskListData" :key="index">
        <a-card :title="item.taskName" :bordered="false">
          <span slot="extra" v-if="item.code !== 'task_close_product_order'">
            <a-switch
                checked-children="开"
                un-checked-children="关"
                :checked="!!Number(item.value)"
                @click="handleShowSwitch($event, item)"
            />
          </span>
          <span slot="extra" v-else>
            <a-switch
                checked-children="开"
                un-checked-children="关"
                :checked="!Number(item.value)"
                @click="handleShowSwitch($event, item)"
            />
          </span>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {changeTaskStatus, getTaskListApi} from "@/views/cmsPage/dqPlatform/taskSwitchManage/_apis"

export default {
  data() {
    return {
      taskListData: {}
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 点击 */
    async handleShowSwitch(e, item) {
      let codeTip = ''
      if (item.code === 'task_close_product_order') {
        codeTip = '关闭本开关后，截拍的拍品将不能生成订单，请谨慎操作！！！'
      } else {
        codeTip = '请谨慎操作！！！'
      }
      this.$confirm({
        title: '确定修改' + item.taskName + '状态',
        content: codeTip,
        onOk: async () => {
          const res = await changeTaskStatus({code: item.code})
          if (res.status !== '200') return
          await this.getList()
        },
        onCancel() {},
      });
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getTaskListApi()
      this.$loading.hide()
      if (res.status !== '200') return
      this.taskListData = res.data
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
